<template>
	<footer class="metting-bottom ">
		<div class="metting-bottom-1 page-content">
			<div class="metting-bottom-2" style="margin-right: 425px;line-height: 20px;">
				<div>
					<el-image :src="require('@/assets/images/logo_white.png')"></el-image>
				</div>
				<div>
					<p>Select, Forcus, Share, Cite</p>
				</div>
				<div>
					<el-image :src="require('@/assets/images/academia.png')"></el-image>
				</div>
				<div>
					<el-image :src="require('@/assets/images/researchgate.png')"></el-image>
				</div>

			</div>
			<div class="metting-bottom-2">
				<p style="font-size: 16px; color: white; padding-bottom: 10px">
					Scholar Boosting
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="javascript:void(0);">Envolving In</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="javascript:void(0);">Refer A Friend</el-link>
				</p>
			</div>
			<div class="metting-bottom-2">
				<p style="font-size: 16px; color: white; padding-bottom: 10px">
					Tools
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="javascript:void(0);">Subscibe</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="javascript:void(0);">Contact Us</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="javascript:void(0);">Twitter</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="javascript:void(0);">Facebook</el-link>
				</p>

			</div>
			<div class="metting-bottom-2">
				<p style="font-size: 16px; color: white; padding-bottom: 10px">
					My ScienceAlerts
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="javascript:void(0);">Creat Account</el-link>
				</p>
				<p>
					<el-link :underline="false" class="elLink" href="javascript:void(0);">Sign In</el-link>
				</p>
			</div>
		</div>
		<div class="metting-bottom-4">
			<div class="page-content">
				<div style="float: left;">
					<p>Copyright © 2012-2022 ScienceAlerts - All Rights Reserved., except certain content provided by
						third
						parties.</p>
					<p>Cookies are used by this site. To decline or learn more, visit our Cookies page.</p>
				</div>
				<div style="float: right;padding-top: 19px;">
					<p>Terms and Conditions | Privacy Policy</p>
				</div>
			</div>
		</div>

	</footer>
</template>

<script>
	export default {
		name: "app-footer",
		data() {
			return {};
		},
	};
</script>
<style scoped lang="scss">
	.metting-bottom {
		padding-top: 40px;
		height: 330px;
		color: white;
		background-color: #6b57a5;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.metting-bottom-1 {
		display: flex;
		line-height: 7px;
	}

	.metting-bottom-2 {
		margin-right: 6%;
	}

	.el-link.el-link--default {
		color: white;
	}

	.elLink:hover {
		color: #add6ff;
	}

	.metting-bottom-3 {
		display: flex;
		margin-top: 5%;
	}

	.metting-bottom-4 {
		// bottom: 0;
		// position: fixed;
		margin-top: 100px;
		height: 64px;
		width: 100%;
		padding: 7px 0 7px 0;
		color: white;
		line-height: 7px;
		font-size: 12px;
		background: #2f2f2f;
	}
</style>

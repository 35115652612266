<template>
	<header :style="{ backgroundColor: color, height: '125px' }">
		<div>
			<div style="background-color: #6b57a5; color: white;height: 40px;">
			</div>
			<div style="display: flex;margin: auto;padding-top: 15px;width: 1200px;">
				<div class="metting-head-left">
					<el-image :src="require('@/assets/images/logo.png')"></el-image>
				</div>
				<div class="metting-head-right">
					<div class="mettingNav">
						<!-- mode="horizontal" vertical -->
						<el-menu :default-active="activeIndex" :router="routerR" class="el-menu-demo"
							active-text-color="#6b57a5" :background-color=color mode="horizontal" menu-trigger="click"
							unique-opened="true">
							<el-menu-item index="/index">Home</el-menu-item>
							<el-menu-item index="/hcpQuery">HCP Query</el-menu-item>
							<el-menu-item index="/digests">Digests</el-menu-item>
							<el-menu-item index="/services">Services</el-menu-item>
							<el-menu-item index="/contact">Contact</el-menu-item>
							<!-- <el-submenu index="notice">
								<template #title></template>
								<el-menu-item index="learning"></el-menu-item>
								<el-menu-item index="important"></el-menu-item>
								<el-menu-item index="progress"></el-menu-item>
								<el-menu-item index="Journalism"></el-menu-item>
								<el-menu-item index="resourceDow"></el-menu-item>
							</el-submenu> -->
						</el-menu>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import $http from "@/request/http";
	export default {
		name: "app-header",
		data() {
			return {
				color: '#f3f7ff',
				routerR: true,
				activeIndex: "/index",
			};
		},
		watch: {
			// 监听路由变化
			"$route.path": {
				handler(routePath) {
					this.initMenuActive(routePath);
				},
				immediate: true,
			},

		},
		methods: {
			initMenuActive(routePath) {
				// if (routePath == "/about") {
				//   this.login = false;
				// }
				this.activeIndex = routePath;
				if (routePath == '/') {
					this.activeIndex = '/index'
				}
				if (routePath != '/' && routePath != '/index') {
					this.color = 'white'
				} else {
					this.color = '#f3f7ff'
				}
			},
		},
		created() {

		},

	};
</script>
<style scoped lang="scss">
	.metting-head-left {
		width: 16%;
	}

	.metting-head-right {
		padding-left: 40%;
	}

	.mettingNav {
		.el-menu-item {
			text-align: center;
			font-size: 16px;
			font-weight: 500;
			color: black;
		}
	}

	.el-menu.el-menu--horizontal {
		border-bottom: none;
	}
</style>
<style>
	.el-menu--popup {
		z-index: 100;
		min-width: 105px;
		border: none;
		padding: 5px;
		border-radius: var(--el-border-radius-small);
		-webkit-box-shadow: var(--el-box-shadow-light);
		box-shadow: var(--el-box-shadow-light);
	}

	.el-submenu__title {
		text-align: center;
		/* border-bottom: none; */
		font-size: 16px;
		font-weight: 500;
	}

	.el-menu-item {
		text-align: center;
		font-size: 16px;
		font-weight: 500;
		color: black;
	}

	.el-menu--horizontal>.el-submenu .el-submenu__title {
		color: black;
	}
</style>

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import * as ElIcons from '@element-plus/icons'
import "./assets/css/style.css";
import axios from "axios";
import VueAxios from "vue-axios";
import {
	createApp
} from "vue";
import ECharts from "vue-echarts";
import App from "./App.vue";

const app = createApp(App)
for (const [key, component] of Object.entries(ElIcons)) {
	app.component(key, component)
}
app.use(store)
	.use(router)
	.use(ElementPlus)
	.use(VueAxios, axios)
	.use(ECharts)
	.mount("#app");

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

import {
	createStore
} from 'vuex'


export default createStore({

	state() {
		return {
			userInfo: [],
			tokenInfo: [],
			isLogin: false
		};
	},

	mutations: {
		setUserInfo(state, userInfo) {
			sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
			state.userInfo = userInfo
		},
		setTokenInfo(state, tokenInfo) {
			sessionStorage.setItem('tokenInfo', JSON.stringify(tokenInfo))
			state.tokenInfo = tokenInfo
		},
		setIsLogin(state, isLogin) {
			sessionStorage.setItem('isLogin', isLogin)
			state.isLogin = isLogin
		}

	},

	getters: {
		getUserInfo: state => {
			state.userInfo = sessionStorage.getItem("userInfo") == "" ? "" : JSON.parse(sessionStorage.getItem("userInfo"))
			return state.userInfo
		},
		getTokenInfo: state => {
			state.tokenInfo = sessionStorage.getItem("tokenInfo") == "" ? "" : JSON.parse(sessionStorage.getItem("tokenInfo"))
			return state.tokenInfo
		},
		getIsLogin: state => {
			state.isLogin = sessionStorage.getItem("isLogin")
			return state.isLogin
		}
	},
	

});

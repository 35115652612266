<template>
	<div class="metting">
		<app-header v-if="header_show" @search="searchKey"></app-header>
		<main class="navContent" v-bind:class="{ navContentWidth: header_show }">
			<div v-if="header_show">
				<el-affix :offset="620" style="width: 100px;margin-left: 93%;">
					<div style="">
						<div @click="returnTop()">
							<el-image style="" :src="require('@/assets/images/Top.png')"></el-image>
						</div>
					</div>
				</el-affix>
			</div>
			<router-view v-on:header="header" v-on:footer="footer" :searchVal="searchVal"></router-view>
		</main>
		<app-footer v-if="footer_show"></app-footer>
	</div>
</template>
<script>
	import Header from "./components/meetingHead";
	import Footer from "./components/meetingBottom";
	export default {
		data() {
			return {
				isShowQR: false,
				searchVal: '',
				header_show: true,
				footer_show: true,
			};
		},
		components: {
			"app-header": Header,
			"app-footer": Footer,
		},
		methods: {

			mouseLeaveQR() {
				this.isShowQR = false
			},
			//是否显示头部
			header: function(bool) {
				this.header_show = bool;
			},
			//是否显示底部
			footer: function(bool) {
				this.footer_show = bool;
			},
			searchKey(val) {
				this.searchVal = val
			},
			returnTop() {
				var timer = setInterval(function() {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
					this.isTop = true;
					if (osTop === 0) {
						clearInterval(timer);
					}
				}, 50)
			},
			// returnKefu(){
			// 	 window.open('http://wpa.qq.com/msgrd?v=3&uin='+1044660476+'&site=qq&menu=yes','_brank');
			// }

		},
	};
</script>

<style lang="scss" scoped>
	/* 导航下页面 */
	.metting {
		// padding-top: 2%;
	}

	.navContent {
		// margin: 0 auto;
		color: #333333;
		min-height: 780px;
		padding-bottom: 30px;
		background-color: #fff;
	}

	.navContentWidth {
		width: 100%;
	}
</style>

<template>
	<div>
		<section class="bg1">
			<div class="page-content" style="padding-top: 110px;">
				<div style="font-weight: bold;font-size: 32px;color: #333333;line-height: 10px;">
					<p>"Open Access" is a particular</p>
					<p><span>manifesto of </span><span style="color: #6b57a5;">ScienceAlerts.</span></p>
				</div>
				<div>
					World of scholarly services needs a deeper investigate<br>
					on its requirements.
				</div>
				<el-button type="danger" style="background-color: #6b57a5;border-color: #6b57a5;margin-top: 40px;">
					<el-link :underline='false' href='mailto:' style="color: white;">Get in
						Touch </el-link>
				</el-button>
			</div>
		</section>
		<section class="page-content" style="margin-top: 80px;">
			<div style="display: flex;">
				<div style="margin-right: 40px;width: 385px;height: 269px;text-align: center;">
					<el-image style="height: 75px;" :src="require('@/assets/images/bg2-1.png')"></el-image>
					<h5>Scholarly Communication</h5>
					<p style="text-align: justify;">We are looking for opportunities to pro- vide the most
						comprehensive
						and ease of use services and
						solutions for active individuals or corporate parties of "Scholarly Communication".</p>
				</div>
				<div style="margin-right: 40px;width: 385px;height: 269px;text-align: center;">
					<el-image style="height: 75px;" :src="require('@/assets/images/bg2-2.png')"></el-image>
					<h5>Scientific Research Acceleration</h5>
					<p style="text-align: justify;">Most of the emerg- ing research works need to being accel- erated
						and aligned in multiple
						dissemi- nating platforms to boost and promote their research findings.</p>
				</div>
				<div style="width: 385px;height: 269px;text-align: center;">
					<el-image style="height: 75px;" :src="require('@/assets/images/bg2-3.png')"></el-image>
					<h5>Open Knowledge Sharing</h5>
					<p style="text-align: justify;">Our world is Open! Research activities and scholarly movements are
						looking for opportunities to
						disseminate and global- ize in open access platforms in order to make more visibility and
						discoverability.</p>
				</div>
			</div>
		</section>
		<section class="page-content" style="margin-top: 80px;">
			<div style="display: flex;">
				<div style="margin-right: 80px;">
					<el-image style="width: 380px;" :src="require('@/assets/images/bg2-4.png')"></el-image>
				</div>
				<div>
					<h2><span>About</span><span style="color: #6b57a5;margin-left: 10px;">ScienceAlerts</span></h2>
					<p style="text-align: justify;">Science-Alerts is a platform for scholars, publishers to share their
						outbreaking discovers. It transfers, spreads and advocates re search papers.</p>
					<p style="text-align: justify;">It provides authors or researchers up-to-date new discovers in
						different displinary and difference publications. The subsciber might receive our selective
						content to find their trending technology, new ideas, comments in scientific researches.
						Subscriber can read the Editor’s choice, Hot Papers Recommendation, Selected Papers
						Recommendation, Best Paper Recommendation, Selected Papers Communication, Editor's Choice
						Monthly Digest, Most Downloaded articles ect.</p>
					<p>All content present here are free of charge.</p>
					<div>
						<el-image :src="require('@/assets/images/bg2-5.jpg')" style="width: 740px;height: 110px;">
						</el-image>
					</div>
				</div>
			</div>
		</section>
		<section style="margin-top: 80px;background-color: #f3f9fc;height: 456px;">
			<div style="text-align: center;padding-top: 30px;">
				<h2>Browse Digests by <span style="color: #6b57a5;">Subject</span></h2>
			</div>

			<div class="page-content subject" style="display: flex;justify-content: center;">
				<ul style="list-style: none;width: 400px;">
					<li @click="browseDigests()">Aerospace Science</li>
					<li @click="browseDigests()">Agroforestry & Food</li>
					<li @click="browseDigests()">Amynology</li>
					<li @click="browseDigests()">Biology</li>
					<li @click="browseDigests()">Bioscience & Biotechnology</li>
					<li @click="browseDigests()">Chemical Science</li>
					<li @click="browseDigests()">Computing</li>
					<li @click="browseDigests()">Earth Science</li>
				</ul>
				<ul style="list-style: none;width: 400px;">
					<li @click="browseDigests()">Engineering Science</li>
					<li @click="browseDigests()">Environmental Science</li>
					<li @click="browseDigests()">Flora & Fauna Science</li>
					<li @click="browseDigests()">Interdisciplinary</li>
					<li @click="browseDigests()">Managment, Economics & Finance</li>
					<li @click="browseDigests()">Math & Quantitative Science</li>
					<li @click="browseDigests()">Medical Science</li>
				</ul>
				<ul style="list-style: none;">
					<li @click="browseDigests()">Molecular Genetics</li>
					<li @click="browseDigests()">Neuroscience & Neuropsychiatry</li>
					<li @click="browseDigests()">Pharmaceutics & Toxicology</li>
					<li @click="browseDigests()">Physical Science</li>
					<li @click="browseDigests()">Psychological Science</li>
					<li @click="browseDigests()">Resources & Materials Allocation</li>
					<li @click="browseDigests()">Social Sciences</li>
				</ul>

			</div>
		</section>
		<section class="bg3 page-content" style="margin-top: 80px;">
			<div @click="startCalculating()"
				style="cursor: pointer;padding: 10px 0 0 20px;margin: auto;width: 186px;height: 36px;border-radius: 5px;border: solid 1px #ffffff;color: white;margin: 342px 496px;position: absolute;">
				START CALCULATING
			</div>
		</section>
		<section class="page-content" style="margin-top: 80px;">
			<div style="text-align: center;padding-top: 30px;">
				<h2>Globale <span style="color: #6b57a5;">Partners</span></h2>
			</div>
			<div>◆ 知名出版商</div>
			<div style="display: flex;flex-wrap: wrap;padding-left: 60px;">
				<el-link :underline='false' :href="item.web" target="view_window" v-for="(item,index) in publisher1"
					:key="index">
					<el-card class="box-card" style="height: 70px;margin-top: 20px;margin-right: 20px;">
						<el-image :src="item.src"></el-image>
					</el-card>
				</el-link>
			</div>
			<p>◆ 知名数据库</p>
			<div style="display: flex;flex-wrap: wrap;padding-left: 60px;">
				<el-link :underline='false' :href="item.web" target="view_window" v-for="(item,index) in publisher2"
					:key="index">
					<el-card class="box-card" style="height: 70px;margin-top: 20px;margin-right: 20px;">
						<el-image :src="item.src"></el-image>
					</el-card>
				</el-link>
			</div>
		</section>
		<section class="page-content" style="margin-top: 80px;margin-bottom: 80px;">
			<div style="text-align: center;padding-top: 30px;">
				<h2>Latest <span style="color: #6b57a5;">News</span></h2>
			</div>
			<div style="display: flex;">
				<div style="margin-right: 10px;width: 837px;">
					<div>
						<div>
							<p>10 issues in Frontiers of Science Research.</p>
							<p><span style="color: #b0b0b0;">丨June 1, 2022</span>
								<el-link :underline='false' href="/news"
									style="color: #fe7568;float: right;margin-right: 50px;">
									Read more>
								</el-link>
							</p>
						</div>
						<div>
							<p>10 issues in Frontiers of Engineering Technology Research.</p>
							<p><span style="color: #b0b0b0;">丨June 1, 2022</span>
								<el-link :underline='false' href="/news"
									style="color: #fe7568;float: right;margin-right: 50px;">
									Read more>
								</el-link>
							</p>
						</div>
						<div>
							<p>10 issues in Frontiers of Industrial Technology Research.</p>
							<p><span style="color: #b0b0b0;">丨MJune 1, 2022</span>
								<el-link :underline='false' href="/news"
									style="color: #fe7568;float: right;margin-right: 50px;">
									Read more>
								</el-link>
							</p>
						</div>
					</div>
				</div>
				<div>
					<el-image style="" :src="require('@/assets/images/index-bg4.png')"></el-image>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				search: '',
				searchType: 'Title',
				publisher1: [{
						src: require('@/assets/images/elseviep.png'),
						web: "https://www.elsevier.com/",
					},
					{
						src: require('@/assets/images/tay.png'),
						web: "https://www.tandfonline.com"
					},
					{
						src: require('@/assets/images/sage.png'),
						web: "https://journals.sagepub.com/"
					},
					{
						src: require('@/assets/images/wiley.png'),
						web: "https://www.wiley.com/"

					},
					{
						src: require('@/assets/images/bnj.png'),
						web: "https://www.bmj.com/"
					},
					{
						src: require('@/assets/images/npg.png'),
						web: ""
					},
					// {
					// 	src: require('@/assets/images/no.png'),
					// 	web: "https://www.wolterskluwer.com/en"
					// },
					{
						src: require('@/assets/images/cam.png'),
						web: "https://www.cambridge.org/"
					},
					{
						src: require('@/assets/images/ws.png'),
						web: "https://www.worldscientific.com/"
					},
					{
						src: require('@/assets/images/inder.png'),
						web: "https://www.inderscience.com/"
					},
				],
				publisher2: [{
						src: require('@/assets/images/clar.png'),
						web: "https://clarivate.com/"
					},
					{
						src: require('@/assets/images/cope.png'),
						web: "https://publicationethics.org/"
					},
					{
						src: require('@/assets/images/cro.png'),
						web: "https://www.crossref.org/"
					},
					{
						src: require('@/assets/images/doi.png'),
						web: "https://www.doi.org/"
					},
					{
						src: require('@/assets/images/orcid.png'),
						web: "https://orcid.org/"
					},
					{
						src: require('@/assets/images/publons.png'),
						web: "https://publicationethics.org/"
					},
					{
						src: require('@/assets/images/rese.png'),
						web: "https://www.thomsonreuters.com/en.html"
					},
					{
						src: require('@/assets/images/scopus.png'),
						web: "https://www.scopus.com/"
					},
					{
						src: require('@/assets/images/xsgz.png'),
						web: "http://www.acagrid.com/"
					}
				],
			};

		},
		methods: {
			browseDigests() {
				this.$router.push("/digests");
			},
			startCalculating() {
				this.$router.push("/hcpQuery");
			}
		},

		created() {

		},
		mounted() {

		},
		watch: {

		},
		//是否引入头部和底部
		created: function() {

		},

	};
</script>
<style lang="scss" scoped>
	.bg1 {
		margin-top: -53px;
		height: 745px;
		background-image: url("../assets/images/index_bg1.png");
		border: 0 none;
		background-size: cover;
		background-repeat: no-repeat;
		// background-position: center;
	}

	.bg3 {
		height: 468px;
		background-image: url("../assets/images/index-bg3.png");
		border: 0 none;
		// background-size: cover;
		background-repeat: no-repeat;
		// background-position: center;
	}

	.subject>ul>li {
		cursor: pointer;
		margin-top: 5px;
		height: 30px;
		width: 300px;
		border-bottom: solid 1px #d5d5d5;
	}

	.subject>ul>li:hover {
		color: #fe7568;
	}

	.el-card {
		--el-card-padding: 0px;
	}
</style>

import {
	createRouter,
	createWebHistory
} from "vue-router"; //createWebHashHistory
import home from "../views/index.vue";

const routes = [

	{
		path: "/index",
		name: "index",
		component: home,
		meta: {
			index: 1,
			title: 'ScienceAlerts  -  index'
		},
	},
	{
		path: "",
		name: "",
		component: () => import("../views/index.vue"),
	},

	{
		path: "/hcpQuery",
		name: "hcpQuery",
		component: () => import("../views/hcpQuery.vue"),
		meta: {
			index: 2,
			title: 'ScienceAlerts  -  HCP Query'
		},
	},
	{
		path: "/digests",
		name: "digests",
		component: () => import("../views/digests.vue"),
		meta: {
			index: 3,
			title: 'ScienceAlerts  -  Ddigests'
		},
	},
	{
		path: "/services",
		name: "services",
		component: () => import("../views/services.vue"),
		meta: {
			index: 4,
			title: 'ScienceAlerts  -  services'
		},
	},
	{
		path: "/contact",
		name: "contact",
		component: () => import("../views/contact.vue"),
		meta: {
			index: 5,
			title: 'ScienceAlerts  -  Contact'
		},
	},
	{
		path: "/news",
		name: "news",
		component: () => import("../views/news.vue"),
		meta: {
			index: 6,
			title: 'ScienceAlerts  -  news'
		},
	},
	{
		path: "/details",
		name: "details",
		component: () => import("../views/details.vue"),
		meta: {
			index: 6,
			title: 'ScienceAlerts  -  details'
		},
	},

];
const router = createRouter({
	// history: createWebHashHistory(),
	history: createWebHistory(),
	routes,
});

export default router;
